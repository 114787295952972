//
//
//
//
//
//

import HRlink from '@/components/icons/HRlink.vue';
import Sbis from '@/components/icons/Sbis.vue';
import GobblerIcon from '@/components/icons/GobblerIcon';

export default {
  props: {
    signType: {
      required: true,
      type: String,
    },
    eventType: {
      required: true,
      type: String,
    },
    title: {
      required: false,
      type: String,
    }
  },
  components: { HRlink, Sbis },
  data() {
    return {
      label: null,
    }
  },
  created() {
    const button = {};
    switch (this.signType) {
      case 'hr_link':
        button.icon = HRlink;
        button.title = 'HRlink';
        button.style = 'border: 1px solid #0050C6; margin-right: 4px;';
        button.titleStyle = 'color: #0050C6;';        
        break;
      case 'govKey':
        button.icon = GobblerIcon;
        button.title = 'Госключ';
        button.style = 'border: 1px solid #0A2996; margin-right: 4px;';
        button.titleStyle = 'color: #0A2996 !important;';        
        break;
      case 'sbis':
        button.icon = Sbis;
        button.title = 'СБИС';
        button.style = 'border: 1px solid #0C94FF; margin-right: 4px;';
        button.titleStyle = 'color: #0C94FF;';
        break;
      case 'pep':
        button.icon = null;
        button.title = 'ПЭП';
        button.style = 'border: 1px solid #21A971;';
        button.titleStyle = 'color: #21A971;';
        break;
      case 'qds':
        button.icon = null;
        button.title = 'КЭП';
        button.style = 'border: 1px solid #21A971;';
        button.titleStyle = 'color: #21A971;';
        break;
      case 'nqds':
        button.icon = null;
        button.title = 'НЭП';
        button.style = 'border: 1px solid #21A971;';
        button.titleStyle = 'color: #21A971;';
        break;
      case 'scanner':
        button.icon = null;
        button.title = this.title;
        button.style = 'border: 1px solid #21A971;';
        button.titleStyle = 'color: #21A971;';
        break;
      case 'batch':
        button.icon = null;
        button.title = this.title;
        button.style = 'border: 1px solid #21A971;';
        button.titleStyle = 'color: #21A971;';
        break;
      case 'not_signed':
        button.icon = null;
        button.title = 'Не подписан';
        button.style = 'border: 1px solid var(--red, #EA250D);';
        button.titleStyle = 'color: var(--red, #EA250D);';
        break;
      case 'manual':
        button.icon = null;
        button.title = this.title;
        button.style = 'border: 1px solid rgba(0,0,0,.4);';
        button.titleStyle = 'color: #000000;opacity: 0.4;';
        break;
      default:
        button.icon = null;
        button.title = this.title;
        button.style = 'border: 1px solid rgba(0,0,0,.4);';
        button.titleStyle = 'color: #000000;opacity: 0.4;';
        break;
    }
    this.label = button;
  }
}
